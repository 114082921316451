var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"has-text-centered user-select-none"},[(_vm.profile && _vm.profile.handle)?_c('router-link',{staticClass:"box bigProfileLink",attrs:{"to":String('/u/' + _vm.profile.handle)}},[(_vm.profile)?_c('div',{staticClass:"columns is-mobile is-vcentered is-variable is-1",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"column is-narrow"},[_c('img',{staticClass:"avatar squircle",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":_vm.profile.avatar,"loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"column is-v-centered"},[_c('h1',{staticClass:"title is-6",staticStyle:{"display":"inline"}},[_vm._v("\n            "+_vm._s(_vm.profile.handle)+"\n            "),_c('sup',[_vm._v(" ‍")])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',{staticClass:"kicker2",staticStyle:{"opacity":"0.5","font-size":"12px"}},[_vm._v("\n            See your profile\n            ")])])]):_vm._e()]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"spacer l"}),_vm._v(" "),_c('div',{staticClass:"spacer m"}),_vm._v(" "),_vm._l((_vm.grp),function(g){return _c('div',{key:g.label + 'fsd',staticClass:"settings"},[(g.label)?_c('h5',{staticClass:"title is-7 caps user-select-none menuHeadingLabel",staticStyle:{"color":"#aaa"}},[_vm._v("\n      "+_vm._s(g.label)+"\n    ")]):_vm._e(),_vm._v(" "),(g.layout == 'list')?_c('div',{staticClass:"listLayout"},_vm._l((g.items),function(i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !i.hidden && (!i.hideNotLogged || _vm.isLoggedIn) && (!i.hideLogged || !_vm.isLoggedIn) && (!i.more || _vm.viewAll)
        ),expression:"\n          !i.hidden && (!i.hideNotLogged || isLoggedIn) && (!i.hideLogged || !isLoggedIn) && (!i.more || viewAll)\n        "}],key:i.label,staticClass:""},[(i && i.u)?_c('router-link',{staticClass:"box item is-light",attrs:{"to":_vm.getUrl(i.u)}},[_c('span',{staticClass:"icon",style:({ color: i.color || g.color || '#ccc' })},[_c('i',{class:i.i})]),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v("\n            "+_vm._s(i.label)+"\n            "),(i.specialNum)?_c('span',{staticClass:"",staticStyle:{"opacity":"0.5","margin-left":"10px","font-weight":"normal"}},[(i.specialNum == 'coins' && _vm.$store.main.getters.coinBalanceP)?_c('span',{staticClass:"is-small is-light"},[_vm._v("\n                "+_vm._s(_vm.$store.main.getters.coinBalanceP))]):_vm._e(),_vm._v(" "),(i.specialNum == 'msg' && _vm.$store.main.getters.unreadMsgP)?_c('span',{staticClass:"is-light"},[_vm._v("\n                "+_vm._s(_vm.$store.main.getters.unreadMsgP))]):_vm._e()]):_vm._e()])]):_vm._e()],1)}),0):_c('div',{staticClass:"columns is-mobile is-multiline is-variable is-1 user-select-none"},_vm._l((g.items),function(i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !i.hidden && (!i.hideNotLogged || _vm.isLoggedIn) && (!i.hideLogged || !_vm.isLoggedIn) && (!i.more || _vm.viewAll)
        ),expression:"\n          !i.hidden && (!i.hideNotLogged || isLoggedIn) && (!i.hideLogged || !isLoggedIn) && (!i.more || viewAll)\n        "}],key:i.label,staticClass:"column is-6 is-6-mobile"},[(i && i.u)?_c('router-link',{staticClass:"box item is-light",attrs:{"to":_vm.getUrl(i.u)}},[_c('span',{staticClass:"icon",style:({ color: i.color || g.color || '#ccc' })},[_c('i',{class:i.i})]),_vm._v(" "),_c('span',{staticClass:"label"},[_vm._v("\n            "+_vm._s(i.label)+"\n            "),(i.specialNum)?_c('span',{staticClass:"",staticStyle:{"opacity":"0.5","margin-left":"10px","font-weight":"normal"}},[(i.specialNum == 'coins' && _vm.$store.main.getters.coinBalanceP)?_c('span',{staticClass:"is-small is-light"},[_vm._v("\n                "+_vm._s(_vm.$store.main.getters.coinBalanceP))]):_vm._e(),_vm._v(" "),(i.specialNum == 'msg' && _vm.$store.main.getters.unreadMsgP)?_c('span',{staticClass:"is-light"},[_vm._v("\n                "+_vm._s(_vm.$store.main.getters.unreadMsgP))]):_vm._e()]):_vm._e()])]):_vm._e()],1)}),0),_vm._v(" "),(g.moreBt)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.viewAll),expression:"!viewAll"}],staticClass:"button is-light",staticStyle:{"opacity":"0.5"},on:{"click":_vm.btViewAll}},[_vm._m(0,true),_vm._v(" "),_c('span',[_vm._v("See more")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"spacer l"})])}),_vm._v(" "),(_vm.isLoggedIn)?_c('div',[_c('div',{staticClass:"spacer m"}),_vm._v(" "),_c('div',{staticClass:"button is-dark",staticStyle:{"opacity":"0.3"},on:{"click":_vm.logUserOut}},[_vm._m(1),_vm._v(" "),_c('span',[_vm._v("Log out")])]),_vm._v(" "),_c('div',{staticClass:"spacer m"})]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('hr'),_vm._v(" "),_c('small',{staticStyle:{"color":"#ccc"}},[_vm._v("\n    v."+_vm._s(_vm.$store.main.state.appBuildDate)+" "+_vm._s(_vm.$store.main.state.NODE_ENV)+" "+_vm._s(_vm.$store.main.state.packageVersion)+"\n  ")])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-ellipsis-v"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-sign-out"})])
}]

export { render, staticRenderFns }