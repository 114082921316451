var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container w800 w500"},[_c('div',{staticClass:"spacer m"}),_vm._v(" "),_c('titleBar',{attrs:{"title":" Menu","subtitle":"","centered":false,"titleSize":3,"theme":"transparent","showSignup":true,"containerClass":"donotuse","showDesktop":false,"showAfterScroll":1,"scrollTopOnClick":true,"inline":true,"actionButtonStyle":"round","actions":[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
      ]}}),_vm._v(" "),_c('div',{staticClass:"spacer s"}),_vm._v(" "),_c('div',{staticClass:"spacer m"}),_vm._v(" "),_c('burgerMenu',{on:{"pizza":function($event){return _vm.alert(234)}}}),_vm._v(" "),_c('div',{staticClass:"spacer xl"}),_vm._v(" "),_c('div',{staticClass:"spacer xl"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }