<template>
  <div>
    <div class="has-text-centered user-select-none">
      <router-link
        v-if="profile && profile.handle"
        :to="String('/u/' + profile.handle)"
        class="box bigProfileLink"
        style=""
      >
        <div class="columns is-mobile is-vcentered is-variable is-1" v-if="profile" style="text-align: left">
          <div class="column is-narrow">
            <!--
              <div class="squircle white" style="padding:5px; background:#fff; width:40px; height:40px;"> </div>
              -->
            <img :src="profile.avatar" class="avatar squircle" style="width: 50px; height: 50px" loading="lazy" />

            <!--
          <img :src="profile.avatar" class="img squircle" style="  width:80px; margin-right:0.2em; border-radius: 100px;  border: solid white 3px;box-shadow: 0 0 0 4px #0812d0; max-width: 24vw;">
        -->
          </div>
          <div class="column is-v-centered">
            <h1 class="title is-6" style="display: inline">
              {{ profile.handle }}
              <sup> ‍</sup>
            </h1>
            <br />
            <p class="kicker2" style="opacity: 0.5; font-size: 12px">
              See your profile
              <!--  <div  class="txtNo underlineHover" style=" letter-spacing: 0; text-transform: none;" >
          <span style="opacity:0.5;">‬invt.co<strong>{{profile.url}}</strong></span>
        </div> -->
            </p>
          </div>
        </div>
      </router-link>

      <!--
    <router-link to="/home" class="button is-light is-large is-centered" style=" ">
  
      <span>View my page222</span>
    </router-link>
  -->
    </div>
    <div class="spacer l" />
    <div class="spacer m" />

    <div class="settings" v-for="g in grp" :key="g.label + 'fsd'">
      <h5 class="title is-7 caps user-select-none menuHeadingLabel" v-if="g.label" style="color: #aaa">
        {{ g.label }}
      </h5>
      <div v-if="g.layout == 'list'" class="listLayout">
        <div
          class=" "
          v-for="i in g.items"
          :key="i.label"
          v-show="
            !i.hidden && (!i.hideNotLogged || isLoggedIn) && (!i.hideLogged || !isLoggedIn) && (!i.more || viewAll)
          "
        >
          <router-link v-if="i && i.u" :to="getUrl(i.u)" class="box item is-light" style="">
            <span class="icon" :style="{ color: i.color || g.color || '#ccc' }">
              <i :class="i.i"></i>
            </span>
            <span class="label">
              {{ i.label }}
              <span class=" " v-if="i.specialNum" style="opacity: 0.5; margin-left: 10px; font-weight: normal">
                <span class="is-small is-light" v-if="i.specialNum == 'coins' && $store.main.getters.coinBalanceP">
                  {{ $store.main.getters.coinBalanceP }}</span
                >
                <span class="is-light" v-if="i.specialNum == 'msg' && $store.main.getters.unreadMsgP">
                  {{ $store.main.getters.unreadMsgP }}</span
                >
              </span>
            </span>
          </router-link>
        </div>
      </div>
      <div v-else class="columns is-mobile is-multiline is-variable is-1 user-select-none">
        <div
          class="column is-6 is-6-mobile"
          v-for="i in g.items"
          :key="i.label"
          v-show="
            !i.hidden && (!i.hideNotLogged || isLoggedIn) && (!i.hideLogged || !isLoggedIn) && (!i.more || viewAll)
          "
        >
          <router-link v-if="i && i.u" :to="getUrl(i.u)" class="box item is-light" style="">
            <span class="icon" :style="{ color: i.color || g.color || '#ccc' }">
              <i :class="i.i"></i>
            </span>
            <span class="label">
              {{ i.label }}
              <span class=" " v-if="i.specialNum" style="opacity: 0.5; margin-left: 10px; font-weight: normal">
                <span class="is-small is-light" v-if="i.specialNum == 'coins' && $store.main.getters.coinBalanceP">
                  {{ $store.main.getters.coinBalanceP }}</span
                >
                <span class="is-light" v-if="i.specialNum == 'msg' && $store.main.getters.unreadMsgP">
                  {{ $store.main.getters.unreadMsgP }}</span
                >
              </span>
            </span>
          </router-link>
        </div>
      </div>
      <div v-if="g.moreBt" @click="btViewAll" class="button is-light" v-show="!viewAll" style="opacity: 0.5">
        <span class="icon">
          <i class="fal fa-ellipsis-v"></i>
        </span>
        <span>See more</span>
      </div>
      <div class="spacer l" />
    </div>

    <div v-if="isLoggedIn">
      <div class="spacer m" />
      <div @click="logUserOut" class="button is-dark" style="opacity: 0.3">
        <span class="icon">
          <i class="fal fa-sign-out"></i>
        </span>
        <span>Log out</span>
      </div>
      <div class="spacer m" />
    </div>

    <br />
    <hr />
    <small style="color: #ccc">
      v.{{ $store.main.state.appBuildDate }} {{ $store.main.state.NODE_ENV }} {{ $store.main.state.packageVersion }}
    </small>
    <!--
      <div class="columns is-mobile is-multiline is-variable is-1">
        <div
          class="column is-6 is-6-mobile"
          v-for="i in burgerItems"
          v-show="(!i.hideNotLogged || isLoggedIn) && (!i.hideLogged || !isLoggedIn) && (!i.more || viewAll)"
        >
          <router-link v-if="i && i.u" :to="i.u" class="box is-light" style=" margin-bottom:1px;  padding:10px;  ">
            <span class="icon  " style="color: #ccc; color:#f818d1; color:blue; font-size:20px;">
              <i :class="i.i"></i>
            </span>
            <br />
            <span style="font-weight:bold; margin-top:9px; font-size:12px;">{{ i.label }}</span>
          </router-link>
        </div>
      </div>
  
      <div @click="btViewAll" class="button is-light" v-show="!viewAll" style="opacity:0.5;">
        <span class="icon  ">
          <i class="fal fa-ellipsis-v"></i>
         
        </span>
        <span>See more</span>
      </div>
  
   
  
      <div class="settings" v-if="isLoggedIn">
        <div class="spacer l" />
        <h5 class="title is-6">
          Settings
        </h5>
        <div class="columns is-mobile is-multiline is-variable is-1">
          <div
            class="column is-6 is-6-mobile"
            v-for="i in burgerItemsSettings"
            v-show="(!i.hideNotLogged || isLoggedIn) && (!i.hideLogged || !isLoggedIn) && (!i.more || viewAll)"
          >
            <router-link v-if="i && i.u" :to="i.u" class="box is-light" style=" margin-bottom:1px;  padding:10px;  ">
              <span class="icon  " style="color: #ccc; color:#f818d1; color:blue; font-size:20px;">
                <i :class="i.i"></i>
              </span>
              <br />
              <span style="font-weight:bold; margin-top:9px; font-size:12px;">{{ i.label }}</span>
            </router-link>
          </div>
        </div>
      </div>
  
      
  
  
  
  <div class="columns is-mobile">
  
    <div class="column is-narrow">
            <nav class="LayoutDash__nav sidemenu" >
              <router-link to="/me" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-home-lg-alt"></i>
                </span>
                <span>Deals</span>
              </router-link>
  
  
              <router-link to="/people" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-users-plus"></i>
                </span>
                <span>People</span>
              </router-link>
  
              <router-link to="/discuss" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-comments"></i>
                </span>
                <span>Discuss</span>
              </router-link>
  
  
  
              <router-link to="/posts" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-bookmark"></i>
                </span>
                <span>Saved</span>
              </router-link>
  
  
              <router-link to="/coins" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-bell"></i>
                </span>
                <span>Notifications </span>
                <span class="tag is-danger" style="border-radius:55px">35 </span>
              </router-link>
  
  
  
  
              <router-link to="/people" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-crown"></i>
                </span>
                <span>Leaderboard</span>
              </router-link>
  
  
  
              <router-link to="/settings" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-envelope"></i>
                </span>
                <span>Messages</span>
              </router-link>
  
  </nav>
    </div>
  
  
    <div class="column">
            <nav class="LayoutDash__nav sidemenu" >
              <router-link :to="{name: 'dashhome'}" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-analytics"></i>
                </span>
                <span>Dashboard</span>
              </router-link>
  
  
              <router-link to="/posts" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-badge-check"></i>
                </span>
                <span>Posts</span>
              </router-link>
  
  
              <router-link to="/coins" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-gift"></i>
                </span>
                <span>Coin rewards </span>
                <span class="tag is-warning" style="border-radius:55px">xxxx </span>
              </router-link>
  
  
  
              <router-link to="/promote" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-bullhorn"></i>
                </span>
                <span>Promote (invite friends) </span>
              </router-link>
  
  
              <router-link to="/pageSet" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-user-circle"></i>
                </span>
                <span>Page settings</span>
              </router-link>
  
              <router-link to="/settings" class="button is-light">
                <span class="icon  ">
                  <i class="fal fa-cog"></i>
                </span>
                <span>Settings</span>
              </router-link>
  
  
  
  
              <router-link to="/about">About</router-link> |
              <router-link :to="{name: 'dashhome'}">Dashboard</router-link> |
              <router-link to="/inbox">Messages</router-link>
  
            </nav>
    </div>
   </div>
  
  
  --></div>
</template>
<script>
//import bottomBar from '@/components/nav/bottomBar.vue';
//import settingsTabs from '@/components/nav/settingsTabs.vue';
//import layout from "@/components/layout/LayoutModal.vue";

export default {
  //name: 'Home',
  components: {
    // layout,
    //bottomBar,
  },
  data() {
    return {
      viewAll: false,
      // burgerItems: burgerItems,
      //burgerItemsSettings: burgerItemsSettings,
    };
  },

  methods: {
    logUserOut() {
      this.$store.main.dispatch("logout").then(() => {
        this.$router.push("/?seeyousoon=1");
      });
    },
    getUrl(u) {
      //validate + transform special cases:

      if (u == "USER_PROFILE_URL") {
        return String(this.$store.main.getters.profileUrl);
      }
      return String(u) || "/";
    },

    btViewAll(event) {
      this.viewAll = true;
    },
    catchAllClick() {
      alert("cauu");
      this.$emit("close-burger");
      this.$emit("pizza");
    },
  },

  computed: {
    audiences: function () {
      return [];
      // return audienceData.filter((i) => i.visible); //json...
    },
    grp: function () {
      var burgerItemsLoggedMain = [
        {
          label: "My bots",
          u: "/bots/me",
          hideNotLogged: true,
          i: "fal fa-user-friends",
          color: "#ff7d8f", // "#0bd9d9",
          hidden: !this.betaCreator,
        },
        {
          label: "Explore",
          u: "/explore",
          hideNotLogged: true,
          i: "fal fa-compass",
          color: "#ea49ff", // "#0bd9d9", // "#0bd9d9",
        },

        {
          label: "My Invites",
          u: "/refer",
          hideNotLogged: true,
          i: "fal fa-gift",
          color: "#ea49ff",
        },
        {
          label: "Create a bot",
          u: "/creators",
          hideNotLogged: true,
          i: "fal fa-robot",
          color: "#ff7d8f", // "#0bd9d9",
          hidden: this.betaCreator,
        },

        /*
  {
    label: "Popularity",
    u: "/pages",
    hideNotLogged: true,
    i: "fal fa-analytics", //tachometer
    color: "#1774ff",
  },*/

        /*
         */
        /*
    {
      label: 'Home',
      u: '/',
      hideNotLogged: true,
      i: 'fal fa-home',
      color: '#0bd9d9'
    },
  {
    label: "Coins", //Todo adjust name + show balance, like in other navs. maybe use a computed method to return this data and tweak one prop.
    u: "/coins",
    showCoinsBalance: true,
    hideNotLogged: true,
    specialNum: "coins",
    i: "fal fa-coins",
    color: "#ff8d17",
  },*/
        /*
  
    {
      label: 'Make friends',
      u: '/people',
      hideNotLogged: true,
      i: 'fal fa-user-friends',
      color: '#0bd9d9'
    },   */
        /*
  {
    label: "Chats",
    u: "/messages",
    hideNotLogged: true,
    i: "fal fa-envelope", //a-inbox',
    specialNum: "msg",
    color: "#ff7d8f",
  },
*/
        {
          label: "Go pro",
          u: "/plans",
          hideNotLogged: true,
          i: "fal fa-sparkles",
          color: "#0008ff",
        },
      ];

      var burgerItemsDeals = [
        {
          label: "  Referral programs",
          u: "/programs",
          i: "fal fa-handshake",
        },
        {
          label: "Magazine (blog)",
          u: "/magazine",
          i: "fal fa-book-open",
        },
      ];
      var burgerItemsCommunity = [
        {
          label: "Latest",
          u: "/latest",
          hideNotLogged: true,
          i: "fal fa-newspaper",
        },
        {
          label: "Leaderboard",
          u: "/leader",
          hideNotLogged: true,
          i: "fal fa-users-crown",
        },
        {
          label: "Top 50 pages",
          u: "/top",
          hideNotLogged: true,
          i: "fal fa-star",
        },
        {
          label: "AI learning ressources",
          u: "/learn",
          i: "fal fa-book-open",
        },
        {
          label: "Discord server",
          u: "/discord",
          i: "fab fa-discord", //  https://discord.gg/kYKdsTvheA
        },

        /*
  {
    label: "Matches",
    u: "/matches",
    hideNotLogged: true,
    i: "fal fa-fire",
  },*/
      ];

      var burgerItemsTools = [
        {
          label: "Activity",
          u: "/activity",
          i: "fal fa-flask",
          color: "#88dd19",
        },
        {
          label: "Notifications",
          u: "/notifications",
          i: "fal fa-bell",
          color: "#88dd19",
        },

        {
          label: "Explore Features",
          u: "/features",
          i: "fal fa-flask",
          color: "#88dd19",
        },
        /*
  {
    label: "Browser Extension",
    u: "/extension",
    i: "fab fa-chrome",
    color: "#888",
  },*/
        {
          label: "Website Widgets",
          u: "/widgets",
          hideNotLogged: true,
          i: "fal fa-globe",
          color: "#aaa",
        },
        {
          label: "API",
          u: "/api",
          hideNotLogged: true,
          i: "fal fa-code",
          color: "#aaa",
        },
        {
          label: "Mobile apps",
          u: "/apps",
          hideNotLogged: true,
          i: "fal fa-mobile",
          color: "#333",
        },
        {
          label: "Pro Plans",
          u: "/plans",
          hideLogged: true,
          i: "fal fa-sparkles",
          color: "#0008ff",
        },
      ];
      var burgerItemsHelp = [
        {
          label: "Getting started",
          u: "/getting-started/start",
          i: "fal fa-rocket-launch",
        },
        {
          label: "Community guidelines & disallowed uses",
          u: "/product/community-guidelines",
          i: "fal fa-shield",
        },
        {
          label: "FAQ",
          u: "/getting-started/faq",
          i: "fal fa-question-circle",
        },
        {
          label: "Talk with @support",
          u: "/messages/@support",
          i: "fal fa-life-ring",
        },
        {
          label: "Report a problem",
          u: "/report-abuse",
          i: "fal fa-exclamation-triangle",
        },

        {
          label: "Support us",
          u: "/company/pay-it-forward",
          i: "fal fa-hand-peace",
          more: false,
        },
        {
          label: "Special programs",
          u: "/mag/category/special%20programs",
          i: "fal fa-user-tie",
          more: true,
        },
        {
          label: "Jobs",
          u: "/company/jobs",
          i: "fal fa-user-ninja",
          more: true,
        },
        {
          label: "Debug report",
          u: "/debug",
          i: "fal fa-robot",
          more: true,
        },
        {
          label: "Terms & condition",
          u: "/terms",
          i: "fal fa-book",
          more: true,
        },
        {
          label: "Privacy",
          u: "/privacy",
          i: "fal fa-lock",
          more: true,
        },
      ];

      var burgerItemsSettings = [
        {
          label: "Edit profile",
          u: "/settings/profile",
          hideNotLogged: true,
          i: "fal fa-pen",
        },

        {
          label: "Account Settings",
          u: "/settings/account",
          hideNotLogged: true,
          i: "fal fa-cog",
        },

        {
          label: "Notifications pref",
          u: "/settings/notifications",
          hideNotLogged: true,
          i: "fal fa-bell",
        },
        {
          label: "Monetization  ",
          u: "/settings/monetization",
          hideNotLogged: true,
          i: "fal fa-hand-holding-usd",
        },
        {
          label: "Integrations",
          u: "/settings/integrations",
          hideNotLogged: true,
          i: "fal fa-puzzle-piece",
        },
      ];

      var burgerItems = [
        //public, logged-out version
        /*
    {
      label: 'Home',
      u: '/programs',
      i: 'fal fa-home',
      color: '#1774ff'
    },
    {
      label: 'Explore Features',
      u: '/features',
      i: 'fal fa-flask',
      color: '#88dd19'
    },*/

        {
          label: "Messages",
          u: "/messages",
          i: "fal fa-comments",
          color: "#1bcd7c",
        },
        {
          label: "Creating a bot",
          u: "/new-bot",
          i: "fal fa-lab",
          color: "#bbbbbb",
        },
        /*
  {
    label: "Blog",
    u: "/magazine",
    i: "fal fa-globe",
    color: "#bbbbbb",
  },
  */
        {
          label: " Invites",
          u: "/refer",
          i: "fal fa-gift",
          color: "#ea49ff",
        },

        {
          label: "Leaderboard",
          u: "/leader",
          i: "fal fa-users-crown",
          color: "#1bcd7c",
        },
        /*
    {
      label: 'Signup Free',
      u: '/signup',
      i: 'fal fa-arrow-right',
      hideLogged: true
    },
    {
      label: 'Sign in',
      u: '/login',
      i: 'fal fa-sign-in',
      hideLogged: true
    },*/

        {
          label: "New post",
          u: "/new",
          hideNotLogged: true,
          i: "fal fa-plus",
        },

        /*
    {
      label: 'Repost',
      u: '/repost',
      hideNotLogged: true,
      i: 'fal fa-retweet'
    },
  
    {
      label: 'Onboarding & Daily Rewards',
      u: '/rewards',
      i: 'fal fa-treasure-chest',
      hideNotLogged: true
    },
  
    {
      label: 'Browser Extension',
      u: '/extension',
      i: 'fab fa-chrome'
    },
  
    {
      label: 'Leaderboard',
      u: '/leader',
      hideNotLogged: true,
      i: 'fal fa-users-crown'
    },
    {
      label: 'Pro plans',
      u: '/plans',
      hideNotLogged: true,
      i: 'fal fa-sparkles'
    }*/

        /*
    {
      label: 'Search',
      u: '/search',
      i: 'fal fa-search',
      more: true
    },
    {
      label: 'Promote',
      u: '/promote',
      //hideNotLogged: true,
      more: true,
      i: 'fal fa-bullhorn'
    },
  
    {
      label: 'Badges',
      u: '/badges',
      //hideNotLogged: true,
      more: true,
      i: 'fal fa-badge'
    },
    {
      label: 'Reviews',
      u: '/reviews',
      //hideNotLogged: true,
      more: true,
      i: 'fal fa-star'
    }*/
      ];

      var grpLogged = [
        { label: "", items: burgerItemsLoggedMain, moreBt: false },

        // { label: "Save + Earn", items: burgerItemsDeals, color: "#fb1781", layout: "list", moreBt: false },

        { label: "Community", items: burgerItemsCommunity, color: "#1bcd7c", layout: "list" },

        { label: "Settings", items: burgerItemsSettings, color: "#105ab7", layout: "list" },

        /*
    { label: 'Browse', items: burgerItemsMore, moreBt: true, color: '#888', layout: 'list', moreBt: true },
     */
        { label: "Products", items: burgerItemsTools, layout: "list", color: "#555" },
        { label: "Help & Support", items: burgerItemsHelp, color: "#8ad7df", layout: "list" },
      ];

      var grpPublic = [
        { label: "Explore", items: burgerItems, color: "#555" },
        { label: "Products", items: burgerItemsTools, layout: "list", color: "#555" },
        { label: "Help & Support", items: burgerItemsHelp, color: "#8ad7df", layout: "list", moreBt: true },
      ];

      if (this.isLoggedIn) return grpLogged;
      return grpPublic;
    },

    isLoggedIn: function () {
      return this.$store.main.getters.isLoggedIn;
    }, //id is actually better...  //getters.isLoggedIn},
    userToken: function () {
      return this.$store.main.getters.userToken;
    },
    userId: function () {
      return this.$store.main.getters.userId;
    },
    avatar: function () {
      return this.$store.main.getters.avatar;
    },
    profile: function () {
      return this.$store.main.getters.profile;
    },
    displayName: function () {
      return this.$store.main.getters.displayName;
    },
    profileUrl: function () {
      return this.$store.main.getters.profileUrl || "";
    },
    betaCreator: function () {
      return this.$store.main.getters.profile?.betaCreator;
    },
    unreadNotif: function () {
      return this.unreadNotifP;
    },
    unreadMsg: function () {
      return this.unreadMsgP;
    },
    coinBalance: function () {
      return this.coinBalanceP;
    },
    hideBottomBar: function () {
      return this.$store.main.getters.hideBottomBar;
    },
    hideTopBar: function () {
      return this.$store.main.getters.hideTopBar;
    },
    isOnSearchPage: function () {
      if (!this.$route || !this.$route.name) return false; //sometime it's not loaded yet... like 404 bad routes
      if (this.$route.name.startsWith("search")) return true;
      if (this.$route.name.startsWith("newReferralCode")) return true;
      return false;
    },
    unreadNotifP: function () {
      return this.$store.main.getters.unreadNotifP;
    },
    unreadMsgP: function () {
      return this.$store.main.getters.unreadMsgP;
    },
    coinBalanceP: function () {
      return this.$store.main.getters.coinBalanceP;
    },
    isAdmin: function () {
      return this.$store.main.getters.isAdmin;
    },
  },
};
</script>

<style scoped>
.bigProfileLink {
  padding-bottom: 14px;
  padding-left: 11px;
  padding-top: 12px;
  margin-bottom: 20px;
}
/*=
  #burgerMenu {
    position: absolute;
    min-height: 100vh;
    background: #f7f7f7;
    width: 100%;
    z-index: 99;
    padding: 20px;
    height: 2000px;
  }
  #burgerMenu .box {
    margin-bottom: 0;
  }*/

.columns .item {
  margin-bottom: 1px;
  padding: 10px;
  padding-left: 15px;
}
.columns .column {
  padding-bottom: 0; /* tighten default multiline spacing */
  padding-top: 8px;
}

.item .icon {
  color: #f818d1;
  color: blue;
  font-size: 20px;
}
.item .label {
  font-weight: bold;
  font-size: 12px;
}
.columns .item .label {
  margin-top: 3px;
}

.listLayout .item {
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 10px;
}
.listLayout .label,
.listLayout .icon {
  display: inline;
}
.listLayout .label {
  margin-left: 10px;
}
</style>
