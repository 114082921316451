<template>
  <div class="section">
    <div class="container w800 w500">
      <div class="spacer m" />
      <titleBar
        title=" Menu"
        subtitle=""
        :centered="false"
        :titleSize="3"
        theme="transparent"
        :showSignup="true"
        containerClass="donotuse"
        :showDesktop="false"
        :showAfterScroll="1"
        :scrollTopOnClick="true"
        :inline="true"
        actionButtonStyle="round"
        :actions="[
          {
            icon: 'search',
            to: '/explore',
            hidden: false,
            hiddenTablet: false,
          },
        ]"
      />
      <div class="spacer s" />
      <div class="spacer m" />
      <burgerMenu @pizza="alert(234)" />
      <div class="spacer xl" />
      <div class="spacer xl" />
    </div>
  </div>
</template>
<script>
//import bottomBar from '@/components/nav/bottomBar.vue';
//import settingsTabs from '@/components/nav/settingsTabs.vue';
//import layout from '@/components/layout/LayoutModal.vue';
import burgerMenu from "@/components/nav/burgerMenu.vue";

export default {
  //name: 'Home',
  components: {
    //   layout,
    burgerMenu,
    //bottomBar,
  },
};
</script>
<style scoped>
.page {
  background: #f7f7f7;
}
</style>
